import React from 'react'

const Supplies = () => {
  return (
    <div>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <h1 class="text-center mb-5"> Our Products Supplies</h1>
        </div>
        
        <div class="row g-4 portfolio-container">
            <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/housekeeping.webp" alt=""/>
                        <div class="portfolio-overlay">
                            <a class="btn btn-square btn-outline-light mx-1" href="img/housekeeping.webp" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                            <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">House Keeping Supplies</h3>
                        <h5 class="lh-base mb-0"><i class="fa fa-star">Cleaning Chemicals</i></h5>
                        <h5 class="lh-base mb-0"><i class="fa fa-star">Cleaning Consumables</i></h5>
                        <h5 class="lh-base mb-0"><i class="fa fa-star">Tissue & Toiletries</i></h5>
                        <h5 class="lh-base mb-0"><i class="fa fa-star">Cleaning Tools & Tackles</i></h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/officesupplies.jpg" alt=""/>
                        <div class="portfolio-overlay">
                            <a class="btn btn-square btn-outline-light mx-1" href="img/officesupplies.jpg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                            <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div class="bg-light p-4">
                    <h3 class="text-primary fw-medium mb-2"> Office Supplies</h3>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Stationery Consumables</i></h5>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Pantry Consumables</i></h5>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Safety Consumables</i></h5>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/mep.jpeg" alt=""/>
                        <div class="portfolio-overlay">
                            <a class="btn btn-square btn-outline-light mx-1" href="img/mep.jpeg" data-lightbox="portfolio"><i class="fa fa-eye"></i></a>
                            <a class="btn btn-square btn-outline-light mx-1" href=""><i class="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div class="bg-light p-4">
                    <h3 class="text-primary fw-medium mb-2"> MEP Supplies </h3>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Electrical Consumables</i></h5>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Plumbing Consumables</i></h5>
                    <h5 class="lh-base mb-0"><i class="fa fa-star">Tools & Tackles</i></h5>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>
    </div>
  )
}

export default Supplies
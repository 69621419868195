import React from 'react'

const Aboutus = () => {
  return (
    <div>
    <body>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="wow fadeInUp" data-wow-delay="0.1s">
            <p class="section-title text-secondary justify-content-center"><span></span>Our Team<span></span></p>
            <h1 class="text-center mb-5">Our Clients</h1>
        </div>
                <h1 class="mb-5">This section is currently being developed. We’re working hard to bring you something amazing and will be up and running soon. Check back later for updates!</h1>
                <p class="mb-4">
                </p>
                
                
            </div>
            <div class="col-lg-6">
                <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/underconstruction.png"/>
            </div>
        </div>
    </div>
</div> 
    </body>
   
    </div>
  )
}

export default Aboutus
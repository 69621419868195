import React from 'react'

const Projects = () => {
  return (
    <div>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <p class="section-title text-secondary justify-content-center"><span></span>Our Projects<span></span></p>
            <h1 class="text-center mb-5"> Our MEP Projects</h1>
        </div>
       
        <div class="row g-4 portfolio-container">
            <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.1s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/officeinterior.avif" alt=""/>
                       
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">Office interiors</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.3s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/turnkey1.png" alt=""/>
                       
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">Turnkey Projects</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.5s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/officepainting.jpg" alt=""/>
                        
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">Office Paintings (Regular & Specialized)</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.1s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/ahu.png" alt=""/>
                       
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">AHU installation</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item first wow fadeInUp" data-wow-delay="0.3s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/evcharging.jpg" alt=""/>
                        
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">EV chargeable stations</h3>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 portfolio-item second wow fadeInUp" data-wow-delay="0.5s">
                <div class="rounded overflow-hidden">
                    <div class="position-relative overflow-hidden">
                        <img style={{width: '384px',height:"307px"}} class="img-fluid w-100" src="img/energymanagement.jpg" alt=""/>
                        
                    </div>
                    <div class="bg-light p-4">
                        <h3 class="text-primary fw-medium mb-2">Energy management and sustainability solutions</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Projects
import React from 'react'
import Header from './components/Header'
import Home from './components/Home'
import Header1 from './components/Header1'
import Aboutus from './components/Aboutus'
import Services from './components/Services'
import Supplies from "./components/Supplies"
import Projects from "./components/Projects"
import Team from './components/Team'
import Footer from './components/Footer'
import Clients from './components/Clients'


import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';

const App = () => {
  return (
    <div>
    <Router>
    <Header />
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/header1" element={<Header1 />} />
    <Route path="/aboutus" element={<Aboutus />} />
      <Route path="/services" element={<Services />} />
      <Route path="/supplies" element={<Supplies />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/team" element={<Team />} />
      <Route path="/clients" element={<Clients />} />
    </Routes>
    <Footer />
  </Router>
   
    </div>
  )
}

export default App
import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
    <div class="container-fluid bg-primary text-light footer wow fadeIn" data-wow-delay="0.1s">
    <div class="container py-5 px-lg-5">
        <div class="row g-5">
            <div class="col-md-6 col-lg-3">
                <p class="section-title text-white h5 mb-4">Address<span></span></p>
                <p><i class="fa fa-map-marker-alt me-3"></i><h5>Registered Office :</h5> No-27, Ground Floor, Belathur Colony, Near Ayyappa Temple Road, Kadugudi (Po), Bangalore – 560067</p>
                <p><i class="fa fa-map-marker-alt me-3"></i><h5>Branch Office :</h5>No 80, Morden Park Street, VGN Melrose Gardens, West Tambaram, Chennai - 600045</p>
                <p><i class="fa fa-phone-alt me-3"></i><a href="tel:+919036986339">+91 9036986339</a></p>
                <p><i class="fa fa-phone-alt me-3"></i><a href="tel:+917619631009">+91 7619631009</a></p>
                <p><i class="fa fa-envelope me-3"></i><a href="mailto:info@metaservs.com">info@metaservs.com</a></p>
                <div class="d-flex pt-2">
                    <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-twitter"></i></a>
                    <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-facebook-f"></i></a>
                    <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-instagram"></i></a>
                    <a class="btn btn-outline-light btn-social" href=""><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
            <div class="col-md-6 col-lg-3">
               
            </div>
            <div class="col-md-6 col-lg-3">
               
            </div>
            <div class="col-md-6 col-lg-3">
            <p class="section-title text-white h5 mb-4">Quick Link<span></span></p>
            <Link class="btn btn-link" to="/aboutus">About Us</Link>
            <Link class="btn btn-link" to="/services">Services</Link>
            <Link class="btn btn-link" to="/supplies">Supplies</Link>
            <Link class="btn btn-link" to="/projects">Projects</Link>
            </div>
        </div>
    </div>
    <div class="container px-lg-5">
        <div class="copyright">
            <div class="row">
                <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                    &copy; <a class="border-bottom" >{(new Date).getFullYear()}</a>, All Right Reserved. 
      
      Designed By <a class="border-bottom" href="https://www.rctechbox.com">RCTECHBOX IT SERVICES</a>
                </div>
                
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Footer
import React from 'react'

const Services = () => {
  return (
    <div>
   


<div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <p class="section-title text-secondary justify-content-center"><span></span>Our Services<span></span></p>
            <h1 class="text-center mb-5">What Solutions We Provide</h1>
        </div>
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                        <img class="service-icon flex-shrink-0"  src="img/cleaning.ico"></img>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star">Soft Services & Engineer Services</i></h5>
                    <h5 class="mb-3"><i class="fa fa-star"></i>Cafeteria Vendor Management Services</h5>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                    <img class="service-icon flex-shrink-0"  src="img/business.png"></img>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star"></i>Business Support Services</h5>
                    <h5 class="mb-3"><i class="fa fa-star"></i>Pest Control Services & Horticulture Services</h5>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                    <img class="service-icon flex-shrink-0"  src="img/ahuservice.webp"></img>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star"></i>Low side AHU annual maintenance services</h5>
                    <h5 class="mb-3"><i class="fa fa-star"></i>Specialized cleaning services</h5>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                    <img class="service-icon flex-shrink-0"  src="img/vendor.png"></img>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star">Vendor Management Services</i></h5>
                    <h5 class="mb-3"><i class="fa fa-star">Mail Room Management Services</i></h5>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                    <img class="service-icon flex-shrink-0"  src="img/helpdesk1.png"></img>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star">Helpdesk Management Services</i></h5>
                   <h5 class="mb-3"><i class="fa fa-star">Transport Management Services</i></h5>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="service-item d-flex flex-column text-center rounded">
                    <div class="service-icon flex-shrink-0">
                        <i class="fa fa-trash fa-2x"></i>
                    </div>
                    <h5 class="mb-3"><i class="fa fa-star">Waste Management Services</i></h5>
                    <h5 class="mb-3"><i class="fa fa-star">Property Management Services</i></h5>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Services
import React from 'react'

const Team = () => {
  return (
    <div>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="wow fadeInUp" data-wow-delay="0.1s">
            <p class="section-title text-secondary justify-content-center"><span></span>Our Team<span></span></p>
            <h1 class="text-center mb-5">Our Team Members</h1>
        </div>
        <div class="row g-4">
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                <div class="team-item bg-light rounded">
                    <div class="text-center border-bottom p-4">
                        <img class="img-fluid rounded-circle mb-4" src="img/womenicon.png" alt=""/>
                        <h5>Mrs. Parimala</h5>
                        <span>Customer Relationship Executive & Company Accounts</span>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                <div class="team-item bg-light rounded">
                    <div class="text-center border-bottom p-4">
                        <img class="img-fluid rounded-circle mb-4" src="img/womenicon.png" alt=""/>
                        <h5>Mrs. Roselin Bharathi</h5>
                        <span>Customer Relationship officer & sales</span>
                    </div>
                    
                </div>
            </div>
            <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                <div class="team-item bg-light rounded">
                    <div class="text-center border-bottom p-4">
                        <img class="img-fluid rounded-circle mb-4" src="img/menicon.png" alt=""/>
                        <h5>Mr. Manjunathan A</h5>
                        <span>Business Development & Operations</span>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Team
import React from 'react'

const Aboutus = () => {
  return (
    <div>
    <body>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="row g-5 align-items-center">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                <p class="section-title text-secondary">About Us<span></span></p>
                <h1 class="mb-5">Meta Biz Solutions & Services is an organization built on core values..Quality. Service and Value.</h1>
                <p class="mb-4">Meta Biz Solutions & Services, formerly known as Ample Management Services since its inception in 2012, has evolved into a leading integrated facility management company. We specialize in providing a comprehensive range of soft and technical products, services, and solutions.
                </p>
                <p class="mb-4">Our highly trained and experienced staff, equipped with the latest technology and quality materials, ensures that every task is executed efficiently and to the highest standards. We are committed to exceeding our customer's expectations through tailored solutions and exceptional service. Our employees are not only skilled but also deeply understand the unique needs of our clients.
                </p>
                <p class="mb-4">Furthermore, we are dedicated to creating an environmentally friendly atmosphere by implementing sustainable practices throughout our operations. Choose Meta Biz Solutions & Services for reliable, high-quality, and eco-conscious facility management solutions.
                </p>
                
            </div>
            <div class="col-lg-6">
                <img class="img-fluid wow zoomIn" data-wow-delay="0.5s" src="img/alljobs.webp"/>
            </div>
        </div>
    </div>
</div> 
    </body>
   
    </div>
  )
}

export default Aboutus
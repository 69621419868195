import React from 'react'

const Header1 = () => {
  return (
    <div>
    <div class="container-xxl bg-primary hero-header">
    <div class="container px-lg-5">
    <div>
    
    
    <a href="https://wa.me/919036986339" class="whatsapp-link" target="_blank" rel="noopener noreferrer">
        <img  class="img-fluid wow zoomIn" data-wow-delay="0.5s"  style={{width:"65px",height:"65px", position:"fixed"}} src="img/whatsapp.png" alt="WhatsApp" />
    </a>
    </div>

        <div class="row g-5 align-items-end">
            <div class="col-lg-6 text-center text-lg-start">
                <h1 class="text-white mb-4 animated slideInDown">A - One Stop Solutions for all your office needs</h1>
                <p class="text-white pb-3 animated slideInDown">Meta Biz Solutions & Services offers wide range of "Facility Management services" to fit everyone needs. All work is done by our trained, experienced and professional staff using the latest equipment's and quality material to ensure that all tasks are carried out on time to the highest standards.</p>
               {/* <a href="" class="btn btn-secondary py-sm-3 px-sm-5 rounded-pill me-3 animated slideInLeft">Read More</a>
                <a href="" class="btn btn-light py-sm-3 px-sm-5 rounded-pill animated slideInRight">Contact Us</a>*/}
            </div>
            <div class="col-lg-6 text-center text-lg-start">
                <img class="img-fluid animated zoomIn" src="img/office.avif" alt=""/>
            </div>
        </div>
    </div>

</div>
    <div class="container-xxl py-5">
    <div class="container py-5 px-lg-5">
        <div class="row g-4">
            <div  class="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
                <div  class="feature-item bg-light rounded text-center p-4">
                    <i class="fa fa-3x fa-mail-bulk text-primary mb-4"></i>
                    <h5 class="mb-3">Quality</h5>
                    <p class="m-0">The management is committed to the well being of the employees and plays an important role in providing career growth opportunities to them.</p>
                </div>
            </div>
            <div  class="col-lg-4 wow fadeInUp" data-wow-delay="0.3s">
                <div  class="feature-item bg-light rounded text-center p-4">
                    <i class="fa fa-3x fa-search text-primary mb-4"></i>
                    <h5 class="mb-3">Service</h5>
                    <p class="m-0">Our expertise is in providing solutions, we constantly strive to exceed our customers' expectations and our Employees are skill full and understand the needs of our clients.
                    </p>
                </div>
            </div>
            <div  class="col-lg-4 wow fadeInUp" data-wow-delay="0.5s"> 
                <div  class="feature-item bg-light rounded text-center p-4">
                    <i class="fa fa-3x fa-laptop-code text-primary mb-4"></i>
                    <h5 class="mb-3">Value</h5>
                    <p class="m-0">The core principle of the company is to provide value for customers with strict adherence to all statutory compliances.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
    </div>
  )
}

export default Header1